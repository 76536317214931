
import { useRouter } from "vue-router";
import {
  defineComponent,
  inject,
  ref,
  reactive,
  onMounted,
  Ref,
  PropType,
} from "vue";
import { useI18n } from "vue-i18n";
import { AxiosStatic } from "axios";
import { notification } from "ant-design-vue";
import {
  TableState,
  TableStateFilters,
} from "ant-design-vue/es/table/interface";
import { SorterType, Visit, VisitDocument, VisitType } from "../../types";

import AddDocuments from "../components/AddDocuments.vue";
import {
  PlusCircleOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons-vue";

interface Table {
  columns: TableState["columns"];
  sorter: SorterType;
  data: Array<VisitDocument>;
  pagination: TableState["pagination"];
  loading: boolean;
}

export const VisitComponent = defineComponent({
  components: {
    AddDocuments,
    PlusCircleOutlined,
    InfoCircleOutlined,
    DownloadOutlined,
  },
  props: {
    id: {
      type: Object as PropType<Visit["id"]>,
      required: true,
    },
    showAddDocuments: {
      type: Boolean,
    },
  },
  setup(props) {
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    const { t, d } = useI18n();

    const loading: Ref<boolean> = ref(false);
    const data: Ref<Visit> = ref({
      appointmentAt: new Date().toISOString(),
      contractorId: "0",
      conversationExpiresAt: new Date().toISOString(),
      doctor: "",
      finishedAt: new Date().toISOString(),
      id: "0",
      processStatus: "",
      visitType: "HOUSE" as VisitType,
      nfzVisit: false,
    });
    const addDocumentVisible: Ref<boolean> = ref(props.showAddDocuments);

    const table: Table = reactive({
      columns: [
        {
          title: t("VISIT.TABLE.FILE"),
          dataIndex: "originalFileName",
          sorter: true,
        },
        {
          title: t("VISIT.TABLE.PATIENT_NAME"),
          dataIndex: "patientName",
          sorter: true,
          defaultSortOrder: "descend",
          slots: { customRender: "patientName" },
        },
        {
          title: t("VISIT.TABLE.DATE_ADDED"),
          dataIndex: "uploadedAt",
          sorter: true,
          defaultSortOrder: "descend",
          slots: { customRender: "uploadedAt" },
        },
        {
          title: t("VISIT.TABLE.UPLOADED_BY"),
          dataIndex: "uploadedBy",
          customRender: ({ record }: { record: VisitDocument }) => {
            return `${record.uploadedBy} (${t(
              `USER.TYPE.${record.uploaderRole}`
            )})`;
          },
          sorter: true,
        },
        {
          title: t("VISIT.TABLE.ACTIONS"),
          key: "actions",
          slots: { customRender: "actions" },
        },
      ],
      sorter: {
        columnKey: "uploadedAt",
        field: "uploadedAt",
        order: "descend",
      },
      data: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
      },
      loading: false,
    });

    const getDocuments = async (
      pagination: TableState["pagination"],
      sorter: SorterType
    ) => {
      table.loading = true;
      let link = `patient-portal/api/documentation/${props.id}?page=${
        pagination?.current ? pagination.current - 1 : 0
      }&size=${pagination?.pageSize}`;
      if (sorter) {
        link += `&sort=${sorter.field},${
          sorter.order === "ascend" ? "ASC" : "DESC"
        }`;
      }
      try {
        const documentsRes = await http.get(link);
        table.data = documentsRes.data.content;
        table.pagination = {
          current: documentsRes.data.pageable.pageNumber + 1,
          total: documentsRes.data.totalElements,
          pageSize: documentsRes.data.size,
        };
      } catch {
        notification.open({
          message: t("ERROR.4679"),
          class: "error",
        });
      } finally {
        table.loading = false;
      }
    };
    const goToChat = () => {
      router.push({ name: "Chat", params: { id: props.id } });
    };
    const afterAddDocumentModalSuccess = () => {
      getDocuments(table.pagination, {
        columnKey: "uploadedAt",
        field: "uploadedAt",
        order: "descend",
      });
    };
    const handleTableChange = (
      pagination: TableState["pagination"],
      filters: Array<TableStateFilters>,
      sorter: SorterType
    ) => {
      table.sorter = sorter;
      getDocuments(pagination, sorter);
    };
    const downloadDocument = async (record: VisitDocument) => {
      const documentRes = await http.get(record.url, { responseType: "blob" });
      const url = window.URL.createObjectURL(
        new Blob([documentRes.data], {
          type: documentRes.headers["content-type"],
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        documentRes.headers["content-disposition"]
          .split("filename=")[1]
          .replace(/"/g, "")
      );
      document.body.appendChild(link);
      await link.click();
      document.body.removeChild(link);
    };

    onMounted(async () => {
      loading.value = true;
      http.put(
        `patient-portal/api/notification/deactivate?type=DOCUMENT&appointmentId=${props.id}`
      );
      getDocuments(table.pagination, table.sorter);
      try {
        const visitRes = await http.get(
          `patient-portal/api/appointments/${props.id}`
        );
        data.value = visitRes.data;
      } catch {
        notification.open({
          message: t("ERROR.4678"),
          class: "error",
        });
      } finally {
        loading.value = false;
      }
    });

    return {
      data,
      addDocumentVisible,
      table,
      goToChat,
      afterAddDocumentModalSuccess,
      handleTableChange,
      downloadDocument,
      loading,
      t,
      d,
    };
  },
});
export default VisitComponent;
